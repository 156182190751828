// extracted by mini-css-extract-plugin
export var container = "Product-module--container--65bdd";
export var container__geoip = "Product-module--container__geoip--f3475";
export var container__minfraud = "Product-module--container__minfraud--32606";
export var content = "Product-module--content--dbed3";
export var footer = "Product-module--footer--1a316";
export var header = "Product-module--header--ff35a";
export var heading = "Product-module--heading--e6596";
export var icon = "Product-module--icon--fba7b";
export var infoIcon = "Product-module--infoIcon--749f1";
export var learnMore = "Product-module--learnMore--8c768";
export var links = "Product-module--links--869c9";
export var lockup = "Product-module--lockup--27537";
export var subheading = "Product-module--subheading--2970a";